body.compensate-for-scrollbar {
  overflow : hidden;
}

.fancybox-active {
  height : auto;
}

.fancybox-is-hidden {
  visibility : hidden;
  position   : absolute !important;
  top        : -9999px;
  left       : -9999px;
  margin     : 0;
}

.fancybox-container {}

.fancybox-container * {
  box-sizing : border-box;
}

.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
  position : absolute;
  top      : 0;
  right    : 0;
  bottom   : 0;
  left     : 0;
}

.fancybox-outer {}

.fancybox-bg {
  opacity                    : 0;
  background                 : #1e1e1e;
  transition-property        : opacity;
  transition-duration        : inherit;
  transition-timing-function : cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity                    : .87;
  transition-timing-function : cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-caption,
.fancybox-infobar,
.fancybox-navigation .fancybox-button,
.fancybox-toolbar {
  z-index    : 99997;
  visibility : hidden;
  position   : absolute;
  opacity    : 0;
  direction  : ltr;
  transition : opacity .25s,
               visibility 0s linear .25s;
}

.fancybox-show-caption .fancybox-caption,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-nav .fancybox-navigation .fancybox-button,
.fancybox-show-toolbar .fancybox-toolbar {
  visibility : visible;
  opacity    : 1;
  transition : opacity .25s,
               visibility 0s;
}

.fancybox-infobar {
  left                   : 0;
  min-width              : 44px;
  height                 : 44px;
  color                  : #ccc;
  font-size              : 13px;
  -webkit-font-smoothing : subpixel-antialiased;
  line-height            : 44px;
}

.fancybox-toolbar {
  top   : 0;
  right : 0;
}

.fancybox-stage {
  z-index           : 99994;
  overflow          : visible;
  direction         : ltr;
  -webkit-transform : translate3d(0, 0, 0);
}

.fancybox-is-open .fancybox-stage {
  overflow : hidden;
}

.fancybox-slide {}

.fancybox-slide::before {
  content        : '';
  display        : inline-block;
  width          : 0;
  height         : 100%;
  margin-right   : -.25em;
  vertical-align : middle;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
  display : block;
}

.fancybox-slide--next {
  z-index : 99995;
}

.fancybox-slide--image {
  padding  : 44px 0;
  overflow : visible;
}

.fancybox-slide--image::before {
  display : none;
}

.fancybox-slide--html {
  padding : 6px 6px 0 6px;
}

.fancybox-slide--iframe {
  padding : 44px 44px 0;
}

.fancybox-content {
  display        : inline-block;
  position       : relative;
  max-width      : 100%;
  margin         : 0 0 6px 0;
  padding        : 0;
  padding        : 24px;
  overflow       : auto;
  background     : #fff;
  text-align     : left;
  vertical-align : middle;
}

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function : cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function         : cubic-bezier(0.5, 0, 0.14, 1);
}

.fancybox-can-zoomOut .fancybox-content {
  cursor : -webkit-zoom-out;
  cursor : zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor : -webkit-zoom-in;
  cursor : zoom-in;
}

.fancybox-can-drag .fancybox-content {
  cursor : -webkit-grab;
  cursor : grab;
}

.fancybox-is-dragging .fancybox-content {
  cursor : -webkit-grabbing;
  cursor : grabbing;
}

.fancybox-container [data-selectable='true'] {
  cursor : text;
}

.fancybox-image,
.fancybox-spaceball {
  position            : absolute;
  top                 : 0;
  left                : 0;
  width               : 100%;
  max-width           : none;
  height              : 100%;
  max-height          : none;
  margin              : 0;
  padding             : 0;
  border              : 0;
  background          : transparent;
  -moz-user-select    : none;
  -ms-user-select     : none;
  -webkit-user-select : none;
  user-select         : none;
}

.fancybox-spaceball {
  z-index : 1;
}

.fancybox-slide--html .fancybox-content {
  margin-bottom : 6px;
}

.fancybox-slide--iframe .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--video .fancybox-content {
  width    : 100%;
  height   : 100%;
  margin   : 0;
  padding  : 0;
  overflow : visible;
}

.fancybox-slide--video .fancybox-content {
  background : #000;
}

.fancybox-slide--map .fancybox-content {
  background : #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  height        : calc(100% - 44px);
  margin-bottom : 44px;
  background    : #fff;
}

.fancybox-iframe,
.fancybox-video {
  width      : 100%;
  height     : 100%;
  margin     : 0;
  padding    : 0;
  overflow   : hidden;
  border     : 0;
  background : transparent;
}

.fancybox-iframe {
  vertical-align : top;
}

.fancybox-error {
  width      : 100%;
  max-width  : 400px;
  padding    : 40px;
  background : #fff;
  cursor     : default;
}

.fancybox-error p {
  margin      : 0;
  padding     : 0;
  color       : #444;
  font-size   : 16px;
  line-height : 20px;
}

/* Buttons */

.fancybox-button {
  display        : inline-block;
  width          : 44px;
  height         : 44px;
  margin         : 0;
  padding        : 10px;
  border         : 0;
  border-radius  : 0;
  background     : rgba(30, 30, 30, 0.6);
  outline        : none;
  vertical-align : top;
  transition     : color .2s;
  cursor         : pointer;
}

.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited {
  color : #ccc;
}

.fancybox-button:focus,
.fancybox-button:hover {
  color : #fff;
}

.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color  : #888;
  cursor : default;
}

.fancybox-button svg {
  display  : block;
  position : relative;
  overflow : visible;
}

.fancybox-button svg path {
  fill   : transparent;
  stroke : currentColor;
}

.fancybox-button--play svg path:nth-child(2) {
  display : none;
}

.fancybox-button--pause svg path:nth-child(1) {
  display : none;
}

.fancybox-button--play svg path,
.fancybox-button--share svg path,
.fancybox-button--thumbs svg path {
  fill : currentColor;
}

.fancybox-button--share svg path {
  stroke-width : 1;
}

/* Navigation arrows */

.fancybox-navigation .fancybox-button {
  position : absolute;
  top      : 50%;
  width    : 38px;
  height   : 38px;
  padding  : 6px;
  opacity  : 0;
}

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition : opacity .25s,
               visibility 0s,
               color .25s;
}

.fancybox-navigation .fancybox-button::after {
  content  : '';
  position : absolute;
  top      : -25px;
  left     : -25px;
  padding  : 50px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left : 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  right : 6px;
}

/* Close button on the top right corner of html content */

.fancybox-close-small {
  z-index       : 10;
  position      : absolute;
  top           : 0;
  right         : 0;
  width         : 44px;
  height        : 44px;
  margin        : 0;
  padding       : 6px;
  color         : #555;
  border        : 0;
  border-radius : 0;
  background    : transparent;
  cursor        : pointer;
}

.fancybox-close-small svg {
  opacity      : .8;
  fill         : transparent;
  stroke       : currentColor;
  stroke-width : 1.5;
  transition   : stroke .1s;
}

.fancybox-close-small:focus {
  outline : none;
}

.fancybox-close-small:hover svg {
  opacity : 1;
}

.fancybox-slide--iframe .fancybox-close-small,
.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--video .fancybox-close-small {
  top     : -44px;
  right   : -12px;
  padding : 5px;
  color   : #ccc;
}

.fancybox-slide--iframe .fancybox-close-small:hover svg,
.fancybox-slide--image .fancybox-close-small:hover svg,
.fancybox-slide--video .fancybox-close-small:hover svg {
  color      : #fff;
  background : transparent;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display : none;
}

/* Caption */

.fancybox-caption {
  right       : 0;
  bottom      : 0;
  left        : 0;
  padding     : 25px 44px 25px 44px;
  color       : #fff;
  font-size   : 14px;
  font-weight : 400;
  line-height : 1.5;
}

.fancybox-caption::before {
  background-image : url(data:image/png;
}

.fancybox-caption::after {
  content       : '';
  display       : block;
  position      : absolute;
  top           : 0;
  right         : 44px;
  left          : 44px;
  border-bottom : 1px solid rgba(255, 255, 255, 0.3);
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color           : #ccc;
  text-decoration : none;
}

.fancybox-caption a:hover {
  color           : #fff;
  text-decoration : underline;
}

/* Loading indicator */

.fancybox-loading {
  z-index           : 99999;
  position          : absolute;
  top               : 50%;
  left              : 50%;
  width             : 60px;
  height            : 60px;
  margin            : -30px 0 0 -30px;
  padding           : 0;
  opacity           : .6;
  border            : 6px solid rgba(100, 100, 100, 0.5);
  border-top-color  : #fff;
  border-radius     : 100%;
  background        : transparent;
  -webkit-animation : fancybox-rotate .8s infinite linear;
  animation         : fancybox-rotate .8s infinite linear;
}

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform : rotate(0deg);
    transform         : rotate(0deg);
  }

  to {
    -webkit-transform : rotate(359deg);
    transform         : rotate(359deg);
  }
}

@keyframes fancybox-rotate {
  from {
    -webkit-transform : rotate(0deg);
    transform         : rotate(0deg);
  }

  to {
    -webkit-transform : rotate(359deg);
    transform         : rotate(359deg);
  }
}

/* Transition effects */

.fancybox-animated {
  transition-timing-function : cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */

.fancybox-fx-slide.fancybox-slide--previous {
  opacity           : 0;
  -webkit-transform : translate3d(-100%, 0, 0);
  transform         : translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity           : 0;
  -webkit-transform : translate3d(100%, 0, 0);
  transform         : translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity           : 1;
  -webkit-transform : translate3d(0, 0, 0);
  transform         : translate3d(0, 0, 0);
}

/* transitionEffect: fade */

.fancybox-fx-fade.fancybox-slide--next,
.fancybox-fx-fade.fancybox-slide--previous {
  opacity                    : 0;
  transition-timing-function : cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity : 1;
}

/* transitionEffect: zoom-in-out */

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity           : 0;
  -webkit-transform : scale3d(1.5, 1.5, 1.5);
  transform         : scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity           : 0;
  -webkit-transform : scale3d(0.5, 0.5, 0.5);
  transform         : scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity           : 1;
  -webkit-transform : scale3d(1, 1, 1);
  transform         : scale3d(1, 1, 1);
}

/* transitionEffect: rotate */

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity           : 0;
  -ms-transform     : rotate(-360deg);
  -webkit-transform : rotate(-360deg);
  transform         : rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity           : 0;
  -ms-transform     : rotate(360deg);
  -webkit-transform : rotate(360deg);
  transform         : rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity           : 1;
  -ms-transform     : rotate(0deg);
  -webkit-transform : rotate(0deg);
  transform         : rotate(0deg);
}

/* transitionEffect: circular */

.fancybox-fx-circular.fancybox-slide--previous {
  opacity           : 0;
  -webkit-transform : scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform         : scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity           : 0;
  -webkit-transform : scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform         : scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity           : 1;
  -webkit-transform : scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform         : scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform : translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform         : translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform : translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform         : translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform : translate3d(0, 0, 0) scale(1);
  transform         : translate3d(0, 0, 0) scale(1);
}

/* Share */

.fancybox-share {
  max-width     : 90%;
  padding       : 30px;
  border-radius : 3px;
  background    : #f4f4f4;
  text-align    : center;
}

.fancybox-share h1 {
  margin      : 0 0 20px 0;
  color       : #222;
  font-size   : 35px;
  font-weight : 700;
}

.fancybox-share p {
  margin  : 0;
  padding : 0;
}

.fancybox-share__button {
  display             : inline-block;
  min-width           : 130px;
  margin              : 0 5px 10px 5px;
  padding             : 0 15px;
  border              : 0;
  border-radius       : 3px;
  text-decoration     : none;
  font-size           : 14px;
  font-weight         : 700;
  line-height         : 40px;
  white-space         : nowrap;
  transition          : all .2s;
  -moz-user-select    : none;
  -ms-user-select     : none;
  -webkit-user-select : none;
  user-select         : none;
}

.fancybox-share__button:link,
.fancybox-share__button:visited {
  color : #fff;
}

.fancybox-share__button:hover {
  text-decoration : none;
}

.fancybox-share__button--fb {
  background : #3b5998;
}

.fancybox-share__button--fb:hover {
  background : #344e86;
}

.fancybox-share__button--pt {
  background : #bd081d;
}

.fancybox-share__button--pt:hover {
  background : #aa0719;
}

.fancybox-share__button--tw {
  background : #1da1f2;
}

.fancybox-share__button--tw:hover {
  background : #0d95e8;
}

.fancybox-share__button svg {
  position       : relative;
  top            : -1px;
  width          : 25px;
  height         : 25px;
  margin-right   : 7px;
  vertical-align : middle;
}

.fancybox-share__button svg path {
  fill : #fff;
}

.fancybox-share__input {
  width         : 100%;
  margin        : 10px 0 0 0;
  padding       : 10px 15px;
  color         : #5d5b5b;
  border        : 0;
  border-bottom : 1px solid #d7d7d7;
  border-radius : 0;
  background    : transparent;
  outline       : none;
  font-size     : 14px;
}

/* Thumbs */

.fancybox-thumbs {
  display    : none;
  bottom     : 0;
  margin     : 0;
  background : #fff;
}

.fancybox-thumbs-x {
  overflow-x : auto;
  overflow-y : hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display : block;
}

.fancybox-show-thumbs .fancybox-inner {
  right : 212px;
}

.fancybox-thumbs > ul {
  position    : relative;
  position    : absolute;
  width       : 100%;
  height      : 100%;
  margin      : 0;
  padding     : 0;
  overflow-x  : hidden;
  overflow-y  : auto;
  font-size   : 0;
  white-space : nowrap;
  list-style  : none;
}

.fancybox-thumbs-x > ul {
  overflow : hidden;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width : 7px;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  border-radius : 10px;
  background    : #fff;
  box-shadow    : inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  border-radius : 10px;
  background    : #2a2a2a;
}

.fancybox-thumbs > ul > li {}

.fancybox-thumbs-loading {
  background : rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li {
  background-repeat   : no-repeat;
  background-position : center center;
  background-size     : cover;
}

.fancybox-thumbs > ul > li:before {
  z-index    : 99991;
  content    : '';
  position   : absolute;
  top        : 0;
  right      : 0;
  bottom     : 0;
  left       : 0;
  opacity    : 0;
  border     : 4px solid #4ea7f9;
  transition : all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity : 1;
}

/* Styling for Small-Screen Devices */

@media
  all
  and (max-width : 800px) {
  .fancybox-thumbs {
    width : 110px;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right : 110px;
  }

  .fancybox-thumbs > ul > li {
    max-width : calc(100% - 10px);
  }
}
